import { ref } from "vue";

export default function useToggleExpand() {
  // Data
  const expandedIndexes = ref([]);
  const isExpandedAll = ref(false);

  // Methods
  const onClickToggleExpand = index => {
    const foundIndex = expandedIndexes.value.findIndex(x => x === index);

    if (foundIndex === -1) {
      expandedIndexes.value.push(index);
    } else {
      expandedIndexes.value.splice(foundIndex, 1);
    }
  };

  const onClickToggleExpandAll = length => {
    if (isExpandedAll.value) {
      expandedIndexes.value = [];
    } else {
      expandedIndexes.value = [...Array(length).keys()];
    }

    isExpandedAll.value = !isExpandedAll.value;
  };

  return {
    expandedIndexes,
    isExpandedAll,
    onClickToggleExpand,
    onClickToggleExpandAll
  };
}
